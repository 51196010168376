

// alert('dsi rotinas');

// console.log('DSi_rotinas.js');

// url_app(); //tem que ser chamado antes de tudo para nao dar erro de conexao



async function url_app(){
  
  // console.log('DSi_rotinas.js url app()');
  

  var url       = window.location.href//pega url e faz o tratamento
  var url_base  = 'http://'+location.host

  if (url.includes(':80')){//desenvolvimento
     url = '/api/'
     url_base = url
    //  console.log('desenvolvimento:'+ url)
  }else{ //producao
      
      //  url =  url.replace('/#/','/')
      url = url.substr(0,url.indexOf('#'));
      url =  url.replace('?','')//quando da Enter nos formularios...aparece esse ? no url
    //  console.log('producao:'+ url)
  }
  
  
  sessionStorage['url']      = url  
  localStorage['url_base'] = url_base 
};

export async function meu_url(){

  // aqui so retorno o url para logar .. nao salvo no localstorage 
  // console.log('DSi_rotinas.js meu_url()');
  

  var url       = window.location.href//pega url e faz o tratamento
  // var url_base  = 'http://'+location.host
  if (url.includes(':80')){//desenvolvimento
     url = '/api/'
    //  console.log('desenvolvimento:'+ url)
  }
  else{ //producao
      url = url.substr(0,url.indexOf('#'));
      url =  url.replace('?','')//quando da Enter nos formularios...aparece esse ? no url
    //  console.log('producao:'+ url)
  }
  
   return  url
  // sessionStorage['url']      = url 
};

function alertou(){
  console.log('alertou');
  alert('execute')
};


export function  return_key(){

  //pego o mes e o dia multiplico e chego num valor.. sera analisado   
   let dia = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' }); 
   dia =  dia.slice(3, 5) + dia.slice(0, 2) ; //formato us yyyy-mm-dd
   let a = dia.substring(0,2)
   let b = dia.substring(4,2)
   let c = (a * 3 * b)
   return c
};


 export async function logado(that){

    let key_session  =  sessionStorage.getItem('refresh_size') 
    let logado       =  sessionStorage.getItem('buffer')
    let key          =  return_key().toString() //passo pra string para comparar

    if (key_session == undefined){
        key_session = ''
    }
    key_session  =  key_session.toString()//passo pra string para comparar

    if ( (logado !=='S') || (key !== key_session)) {

      that.$router.push('/')//redireciona para a pagina login
    }

    //chamo o ler_session do app.vue para atualizar o nome do usuario
    that.$emit('executar2', 'ler_session')  
    
    return logado
};

export function devtools(){

  // return false 
  //f12 desabilita
  console.log(Object.defineProperties(new Error, {
    toString: {value() {(new Error).stack.includes('toString@') && alert('Safari devtools')}},
    message: {get() {
        //   alert('Chrome/Firefox devtools')
        // setTimeout(function() {while (true) {eval("debugger")}})
        //  setTimeout(function() {while (true) {alert('Opsss')}}) 

          window.location.replace("https://www.google.com") //impede retornar pelo historico
        
        // window.top.close();
          // Location.reload()
          // window.location.href = "http://www.google.com";
        }},
  }))
};



 export function cripto(entra){
    let A = entra
    

    if (A == undefined){
       A='.|.'
    }

    // console.log('A:'+A);

    let simbolos = [
      'ABCDEFGHIJLMNOPQRSTUVXZYWK ~!@#$%^&*()\/:.[]{}', //0
      'æÆôÂƒòçêùÿøúèñÀ©Ñªº¿Ø×£û®¬¼ë5ÜïÙýÄÅÉö»Á?|,<>"`', //1
      'abcdefghijlmnopqrstuvxzywk1234567890',           //2
      '+á¾¶²îÕµå½íóÇ³üÏé·¹§÷âÎ-ÌàÓß¸°¨þìä¡«'            //3
    ]

    
    //INVERTE STRING
    let AA = ''
    // alert(A.length)
    for (let i=0; i< A.length; i++){
      AA += A[(A.length-1) - i]
    }
    // console.log('AA:'+AA);
    A = AA.trim()

    let T = A.length
    let R =' '
    for (let x = 0; x < T; x++){

          if (simbolos[0].includes( A[x] )){
            let po = (simbolos[0].indexOf(A[x]))
              // console.log('po: '+ po +' achou :|'+A[x]+'| = |'+simbolos[1][po]+'|');
              R += simbolos[1][po] 
          }
          
          else if (simbolos[1].includes( A[x] )){
            let po = (simbolos[1].indexOf(A[x]))
              // console.log('po: '+ po +' achou :|'+A[x]+'| = |'+simbolos[0][po]+'|');
              R += simbolos[0][po] 
          }
          
          else if (simbolos[2].includes( A[x] )){
            let po = (simbolos[2].indexOf(A[x]))
              // console.log('po: '+ po +' achou :|'+A[x]+'| = |'+simbolos[3][po]+'|');
              R += simbolos[3][po] 
          }
          
          else if (simbolos[3].includes( A[x] )){
            let po = (simbolos[3].indexOf(A[x]))
              // console.log('po: '+ po +' achou :|'+A[x]+'| = |'+simbolos[2][po]+'|');
              R += simbolos[2][po] 
          }
        // }
    }
    
    // console.log('A:'+A);
    // console.log('R:'+R);
    return R

  };


  export function permissoes(campo){
     
     //busco dados da session
    
     let r = ''
     
     if (campo == 'superUser'){
        return   sessionStorage.getItem('r0s9i3khepus_is') =='S' 
        // r =   sessionStorage.getItem('r0s9i3khepus_is')=='S' 
        // console.log('permissoes: '+campo +':'+r);
     }
  };



  export async function GetPermissoes(){

    // console.log('GetPermissoes');
      let obj_user     = JSON.parse(sessionStorage['obj_user'])//pega objeto principal
      // console.log('obj_user:', obj_user);
    


      let ag_relatorio      = false
      let superUser         = (obj_user.SUPERUSER =='S')           || false
     
      let agendar           = (obj_user.AGENDAR =='S')             || false
      let cadastrar         = (obj_user.ALTERACADASTRO =='S')      || false
      let is_doutor         = (obj_user.CODIGODOUTOR > 0)          || false
      let alt_convenio      = (obj_user.ALTERASINDICATO  == 'S')   || false
      let ver_historico     = (obj_user.VEHISTORICO  == 'S')       || false
      let ver_evolucao      = (obj_user.VER_EVOLUCAO  == 'S')      || false
      let abre_atendimento  = (obj_user.ABRE_ATENDIMENTO  == 'S')  || false
      


      // doutor pode agendar..cancelar ?
      if (is_doutor){
          agendar        = true
          ag_relatorio   = true
      }


      let user_permissoes = {}

      user_permissoes.superUser         =    superUser
      user_permissoes.is_doutor         =    is_doutor
      user_permissoes.ver_historico     =   ver_historico
      user_permissoes.ver_evolucao      =   ver_evolucao
      user_permissoes.abre_atendimento  =   abre_atendimento
      user_permissoes.agendar           =   (superUser) || agendar  // algumas funcoes libera quando for superUser
      user_permissoes.cadastrar         =   (superUser) || cadastrar     // algumas funcoes libera quando for superUser
      user_permissoes.ag_relatorio      =   (superUser) || ag_relatorio       // algumas funcoes libera quando for superUser
      user_permissoes.alt_convenio      =   (superUser) || alt_convenio       // algumas funcoes libera quando for superUser
             
      
      
      // console.log(permissoes);

      return user_permissoes

    };

    export async function randomString(length){
      const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      let result = ' ';
      const charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
  
      return result;
    }
  
  

  
// export default exports;
export default {
    cripto,
    devtools,
    url_app,
    meu_url,
    logado,
    return_key,
    permissoes,
    alertou,
    GetPermissoes,
    randomString
};